import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import LinkedinIcon from 'NewVersion/icons/LinkedinIcon'
import LogoIcon from 'NewVersion/icons/Logo'
import SpotifyIcon from 'NewVersion/icons/SpotifyIcon'
import XIcon from 'NewVersion/icons/XIcon'
import YoutubeIcon from 'NewVersion/icons/YoutubeIcon'
import 'NewVersion/styles/Footer.scss'

const Footer = () => {
  const xarxesSocials = [
    {
      id: '1',
      icon: <FacebookIcon />,
      link: 'https://www.facebook.com/firamediterraniamanresa/',
      ariaLabel: 'Facebook',
    },
    {
      id: '2',
      icon: <InstagramIcon />,
      link: 'https://www.instagram.com/fmediterrania/',
      ariaLabel: 'Instagram',
    },
    {
      id: '4',
      icon: <XIcon />,
      link: 'https://twitter.com/FMediterrania',
      ariaLabel: 'Twitter',
    },
    {
      id: '5',
      icon: <YoutubeIcon />,
      link: 'https://www.youtube.com/FIRAMEDITERRANIA',
      ariaLabel: 'Youtube',
    },
    {
      id: '3',
      icon: <LinkedinIcon />,
      link: 'https://www.linkedin.com/company/5245888',
      ariaLabel: 'Linkedin',
    },
    {
      id: '6',
      icon: <SpotifyIcon />,
      link: 'https://open.spotify.com/user/firamediterraniamanresa?si=62074da24daa493a',
      ariaLabel: 'Spotify',
    },
  ]

  return (
    <footer>
      <div className={'footer'}>
        <div>
          <LogoIcon />
        </div>
        <div className="url">
          <p>www.firamediterrania.cat</p>
        </div>
        <div>
          <p>info@firamediterrania.cat</p>
        </div>
        <div>
          <p>telf +34 938 753 588</p>
        </div>
        <div>
          <p>VERSIÓ 5.4.6</p>
        </div>

        <div className="icons">
          {xarxesSocials.map((socialMedia) => {
            return (
              <div key={socialMedia.id}>
                <a
                  aria-label={socialMedia.ariaLabel}
                  href={socialMedia.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {socialMedia.icon}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </footer>
  )
}

export default Footer
